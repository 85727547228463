import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

Swiper.use([Autoplay, Navigation, Pagination]);

const targetSelector = ".home-v3-mySwiper";

var swiper = new Swiper(targetSelector, {
  grabCursor: true,
  loop: true,
  spaceBetween: 0,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  creativeEffect: {
    prev: {
      translate: [0, 0, -400],
    },
    next: {
      translate: ["100%", 0, 0],
    },
  },
});

swiper.autoplay.stop();

new IntersectionObserver((entries, observer) => {
  if (entries[0].isIntersecting) {
    observer.disconnect();
    swiper.autoplay.start();
  }
}).observe(document.querySelector(targetSelector));
